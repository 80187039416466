<template>
  <div class="showMore pointer" @click="showMore">{{ title }}</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  emits: ['showMore', 'retract'],
  setup (props, context) {
    const title = ref('查看更多政策')
    const flag = ref(false)
    const showMore = () => {
      if (flag.value) {
        title.value = '查看更多政策'
        context.emit('retract', flag.value)
      } else {
        title.value = '收起政策'
        context.emit('showMore')
      }
      flag.value = !flag.value
    }
    return {
      showMore,
      title
    }
  }
})

</script>
<style lang='scss' scoped>
.showMore {
  margin-top: 34px;
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}
</style>
