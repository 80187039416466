import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 极速匹配结果
export function matchResult(data: RequestData) {
  return request({
    url: '/pla/policy/matchResult',
    method: 'post',
    data
  })
}
