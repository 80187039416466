<template>
  <div id="quickMatch" class="base_min_width">
    <div class="quickMatch_content base_width">
      <!-- 提示 -->
      <div class="match_tips">恭喜您，初步评估为您匹配到如下政策补贴</div>
      <!-- 匹配政策 -->
      <QuickMatchItem
        v-for="(item, index) in datalist"
        :key="index"
        :title="item.class_name"
        :total="item.count"
        :itemlist="item.policy_list"
      />
    </div>
  </div>
  <footer class="base_min_width flex">
    <div class="btn_back pointer" @click="back">返回</div>
    <div class="btn_match flex pointer" @click="toPrecise">
      <img src="@/assets/images/quickMatch/quickMatch_content.png" alt="" />
      <span>精准匹配</span>
    </div>
  </footer>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref } from 'vue'
import QuickMatchItem from './components/QuickMatchItem.vue'
import { matchResult } from '@/api/quickMatch'
import { useRoute, useRouter } from 'vue-router'
import { IquickMatch } from '@/types/quickMatch'
import { isDoMatch } from '@/api/preciseMatch'

export default defineComponent({
  components: {
    QuickMatchItem
  },
  setup () {
    const route = useRoute()
    const isPreciseMatch = ref('')
    const router = useRouter()
    const datalist = ref<IquickMatch[]>([])
    const getMatchResult = (socialCreditCode: string) => {
      matchResult({ socialCreditCode }).then(res => {
        datalist.value = res.data.results
      })
    }
    const back = () => {
      router.back()
    }
    const doMatch = () => {
      isDoMatch().then(res => {
        isPreciseMatch.value = res.data.isDoMatch
      }).then(() => {
        if (isPreciseMatch.value === 'YES') {
          router.push({
            path: '/perfectInfoResult'
          // path: '/perfectInfo'
          })
        } else {
          router.push({ path: '/perfectInfo' })
        }
      })
    }
    const toPrecise = () => {
      doMatch()
    }
    onMounted(() => {
      const sc = route.query.socialCreditCode as string | null
      if (!sc) {
        router.replace({ path: 'policyList' })
      } else {
        getMatchResult(sc)
      }
    })
    return {
      datalist,
      back,
      toPrecise
    }
  }
})
</script>
<style lang='scss' scoped>
#quickMatch {
  padding-top: 119px;
  width: 100%;
  background: #f9f9f9;
  padding-bottom: 152px;
  .quickMatch_content {
    margin: auto;
    .match_tips {
      margin-top: 16px;
      height: 71px;
      width: 100%;
      line-height: 71px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      color: #15bb71;
      background: rgba(21, 187, 113, 0.1);
      border-radius: 8px 8px 0px 0px;
    }
    :deep(.startUps),
    :deep(.loan),
    :deep(.subSidy) {
      margin-top: 24px;
      padding: 24px 31px 34px 31px;
      background: #fff;
      .line {
        width: 100%;
        height: 1px;
        background: #f5f5f5;
        margin-top: 24px;
      }
      .content_title {
        max-width: 720px;
      }
    }
    :deep(.startUps_content),
    :deep(.loan_content),
    :deep(.subSidy_content) {
      margin-top: 21px;
    }
  }
}
footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.04);
  opacity: 1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  div {
    width: 195px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    opacity: 1;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  & > div:first-of-type {
    margin-right: 15px;
  }
  & > div:last-of-type {
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    img {
      width: 15px;
      height: 15px;
    }
    span {
      margin-left: 3px;
      font-size: 14px;
      font-weight: bold;
      color: #000000;
    }
  }
}
</style>
