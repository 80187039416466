
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    matchTitle: {
      default: '',
      type: String
    },
    num: {
      default: 0,
      type: Number
    },
    showRightPart: {
      default: false,
      type: Boolean
    },
    rightPartContent1: {
      default: '',
      type: String
    },
    rightPartContent2: {
      default: '',
      type: String
    }
  }
})
