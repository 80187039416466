<template>
 <div id="match_title" class="flex">
    <div class="left_part flex">
      <span class="mask">{{ matchTitle }}</span>
      <span class="maskTitle">{{ matchTitle }}政策</span>
      <span
        class="numTitle"
      >匹配到<span class="num">{{ num }}</span>条</span>
    </div>
    <div v-show="showRightPart" class="right_part flex">
      <span>{{ rightPartContent1 }}</span>
      <span style="color: #333;margin-right: 5px">{{ rightPartContent2 }}</span>
      <span>点此资讯</span>
      <img src="~assets/images/quickMatch/right.png" alt="">
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    matchTitle: {
      default: '',
      type: String
    },
    num: {
      default: 0,
      type: Number
    },
    showRightPart: {
      default: false,
      type: Boolean
    },
    rightPartContent1: {
      default: '',
      type: String
    },
    rightPartContent2: {
      default: '',
      type: String
    }
  }
})
</script>
<style lang='scss' scoped>
#match_title {
  align-items: baseline;
  justify-content: space-between;
  .mask {
    background: rgba(21, 187, 113, 0.1);
    border-radius: 3px;
    padding: 7px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #00a277;
    margin-right: 13px;
  }
  .maskTitle, .numTitle {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .maskTitle {
    margin-right: 10px;
  }
  .num {
    color: #15bb71;
  }
  .right_part {
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
    span:last-of-type {
      color: #3C89FF
    }
    img {
      width: 6px;
      height: 10px;
      margin-left: 11px;
    }
  }
}
</style>
