
import { defineComponent, onMounted, ref } from 'vue'
import QuickMatchItem from './components/QuickMatchItem.vue'
import { matchResult } from '@/api/quickMatch'
import { useRoute, useRouter } from 'vue-router'
import { IquickMatch } from '@/types/quickMatch'
import { isDoMatch } from '@/api/preciseMatch'

export default defineComponent({
  components: {
    QuickMatchItem
  },
  setup () {
    const route = useRoute()
    const isPreciseMatch = ref('')
    const router = useRouter()
    const datalist = ref<IquickMatch[]>([])
    const getMatchResult = (socialCreditCode: string) => {
      matchResult({ socialCreditCode }).then(res => {
        datalist.value = res.data.results
      })
    }
    const back = () => {
      router.back()
    }
    const doMatch = () => {
      isDoMatch().then(res => {
        isPreciseMatch.value = res.data.isDoMatch
      }).then(() => {
        if (isPreciseMatch.value === 'YES') {
          router.push({
            path: '/perfectInfoResult'
          // path: '/perfectInfo'
          })
        } else {
          router.push({ path: '/perfectInfo' })
        }
      })
    }
    const toPrecise = () => {
      doMatch()
    }
    onMounted(() => {
      const sc = route.query.socialCreditCode as string | null
      if (!sc) {
        router.replace({ path: 'policyList' })
      } else {
        getMatchResult(sc)
      }
    })
    return {
      datalist,
      back,
      toPrecise
    }
  }
})
