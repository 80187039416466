<template>
  <div class="startUps">
    <QuickMatchTitle :match-title="title" :num="total" />
    <div class="line" />
    <div class="startUps_content">
      <baseItem
        v-for="(item, index) in itemlist"
        v-show="showStartUps(index)"
        :key="item.pid"
        :item-list="item"
        @toDetail="toDetail(item.pid)"
      />
      <QuickMatchItemShowMore
        v-show="showMoreFlag"
        @showMore="showMore"
        @retract="retract"
      />
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, ref, PropType, computed } from 'vue'
import QuickMatchTitle from './QuickMatchTitle.vue'
import QuickMatchItemShowMore from './QuickMatchItemShowMore.vue'
import baseItem from '@/common/baseItem.vue'
import { IbaseItem } from '@/types/quickMatch'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    QuickMatchTitle,
    baseItem,
    QuickMatchItemShowMore
  },
  props: {
    itemlist: {
      type: Array as PropType<IbaseItem[]>,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const router = useRouter()
    const defaultLength = ref(3)
    const flag = ref(true)
    const showMoreFlag = computed(() => {
      if (props.itemlist.length > defaultLength.value || !flag.value) {
        return true
      } else {
        return false
      }
    })
    const showStartUps = (index: number) => {
      if (index <= defaultLength.value) return true
      else return false
    }
    const showMore = (Flag: boolean) => {
      defaultLength.value = props.itemlist.length
      flag.value = Flag
    }
    const retract = () => {
      defaultLength.value = 2
    }
    const toDetail = (pid: string) => {
      router.push({ path: '/detail', query: { pid } })
    }
    return {
      defaultLength,
      showStartUps,
      showMore,
      retract,
      toDetail,
      showMoreFlag
    }
  }
})
</script>
<style lang='scss' scoped>
</style>
